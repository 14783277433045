<template>
  <div class="privacy p-4" style="height: auto">
    <h1 class="text-center fw-bolder tw-text-size20 mb-1">
      易團Go團媽訂單管理系統 隱私權政策
    </h1>
    <div class="tw-container p-3">
      <p>
        為了幫助您瞭解易團Go團媽訂單管理系統（下稱本系統）如何蒐集、應用及保護您所提供的個人資訊，請您務必詳細閱讀下列資訊：
      </p>
      <article>
        <h5 class="fw-bolder">隱私權政策</h5>
        <p>
          本系統依據「個人資料保護法」之規定，制訂了隱私權保護政策（下稱本政策）。<br /><br />
          關於政策適用範圍以下的隱私權政策，適用於您在本網站活動時，所涉及的個人資料蒐集、運用與保護，但不適用於與本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。本系統不負任何連帶責任。<br /><br />
          在您使用本系統之服務前，敬請詳細閱讀本政策。若您不同意本政策，請勿使用本系統。若您使用本網站之服務則視為您已同意本政策。
        </p>
      </article>
      <br /><br />
      <article>
        <h5 class="fw-bolder">一、個人資料之蒐集、處理及利用：</h5>
        <ul class="ms-3" style="list-style-type: decimal">
          <li>
            當您使用本系統服務，本網站將透過第三方網站所提供的API程式取得您於該網站中紀錄的姓名、電子郵件位址、基本公開資料、社團等個人資料。本系統也會將一或多個
            cookie
            傳送到您的電腦或其他裝置上，以蒐集您使用本網站的技術性資訊，包括但不限於您的網頁要求、您與本網站的互動情況、網際網路通訊協定位址、瀏覽器類型、瀏覽器語言、送出要求的日期和時間，以及一或多個可唯一識別您瀏覽器或帳戶的
            Cookie 等資訊。
          </li>
          <li>
            本系統會將您的個人資料使用於提供、維護、保護及提升我們的服務（包括廣告服務等）及開發新功能、進行研究、用以聯繫您，以及提供內部及外部客戶匿名報告。例如，本系統將利用您所提供的資訊，分析您客戶潛在購買行為，並於日後為您提供相關有益的商品銷售資訊。
          </li>
          <li>
            當您於造訪本系統、於本系統註冊帳號、使用本系統所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
          </li>
          <li>
            本網站在您使用服務信箱、註冊帳號、使用系統功能等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等資訊。
          </li>
          <li>
            於使用本系統時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
          </li>
          <li>
            為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
          </li>
          <li>
            您可以隨時向我們提出請求，以更正或刪除本網站所蒐集您錯誤或不完整的個人資料，請見最下方聯繫管道。
          </li>
        </ul>
      </article>
      <br /> <br />
      <article>
        <h5 class="fw-bolder">二、：Facebook(Meta)資料使用與刪除要求</h5>
        <p>
          本網站經您授權過的 Facebook API 或其他方式蒐集個人資料 (包含: 顯示圖片、姓名、Email、貼文內容、貼文留言等等...)
        </p>
        <p>
          如您不需要再使用本網站服務，您可透過以下管道要求我們將您的資料刪除，我們將再 7 個工作日內處理完畢，一經刪除後，資料將不能復原。
        </p>
        <ul class="ms-5 mt-3" style="list-style-type: decimal">
          <li>
            Line 客服帳號: 易團GO 真人客服 (ID: @482wripj)
          </li>
        </ul>
      </article>
      <br /><br />
      <article>
        <h5 class="fw-bolder">三、與第三人共用個人資料：</h5>
        <p>
          優碼興科技股份有限公司致力於保護本網站使用者的隱私權。除非經您的同意或依法律之規定，本公司不會將您的個人資料訊透漏予第三人。但為提供您其他服務或為改善本網站服務品質及提升網站功能之需要，本網站將於必要範圍內，提供您的個人資料予相關之第三人處理或利用。
        </p>
      </article>
      <br /><br />
      <article>
        <h5 class="fw-bolder">四、本網站對外相關連結：</h5>
        <p>
          本系統網頁提供其他網站的網路連結，您也可經由本系統所提供的連結，點選進入其他網站。但該連結網站不適用於本網站的隱私權保護政策，請您必須參考該連結網站的隱私權保護政策。
        </p>
      </article>
      <br /><br />
      <article>
        <h5 class="fw-bolder">五、個人資料之安全防範：</h5>
        <ul class="ms-3" style="list-style-type: disc">
          <li>
            本系統主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，如有違反保密義務者，將會受到相關的法律處分。
          </li>
          <li>
            如因業務需要有必要委託其他單位提供服務時，本系統亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
          </li>
          <li>
            網際網路資料傳輸無法保證百分之百安全，因此本系統無法保證您所傳送或接收之資料安全無虞，請您注意傳輸過程中須自行承擔之任何風險。
          </li>
        </ul>
      </article>
      <br /><br />
      <article>
        <h5 class="fw-bolder">六、與第三人共用個人資料之政策：</h5>
        <p>
          本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
        </p>
        <p>前項但書之情形包括不限於：</p>
        <ul class="ms-3" style="list-style-type: decimal">
          <li>經由您書面同意。</li>
          <li>法律明文規定。</li>
          <li>為免除您生命、身體、自由或財產上之危險。</li>
          <li>
            與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。
          </li>
          <li>
            當您在本系統的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
          </li>
          <li>有利於您的權益。</li>
          <li>
            本系統委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
          </li>
        </ul>
      </article>
      <br /><br />
      <article>
        <h5 class="fw-bolder">七、修正或變更隱私權政策：</h5>
        <p>
          本網站之隱私權及網站安全政策將因應需求隨時進行修正，修正後的條款將刊登於本系統網站上。
        </p>
      </article>
      <br />
      <p class="fw-bolder">立契約書人：優碼興科技股份有限公司</p>
      <!-- 下一步 -->
      <div class="row mt-5" v-if="is_showNext">
        <div class="col-sm-10 col-12 p-0 mb-sm-0 mb-3">
          <div class="form-check p-0">
            <input
              class="form-check-input m-0 me-2"
              type="checkbox"
              id="Privacy"
              v-model="check"
            />
            <label class="form-check-label fw-bolder text-danger" for="Privacy">
              本人已詳細閱讀隱私權政策
            </label>
          </div>
        </div>
        <div class="col-sm-2 col-12 p-0 text-end">
          <button class="tw-btn tw-btn-success" @click="next">下一步</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      check: false,
      is_showNext: false,
    }
  },
  created() {
    if (this.$route.query.from === 'paymentRecord' || this.$route.query.from === 'register') this.is_showNext = true
  },
  methods: {
    next() {
      if (!this.check) this.SweetAlert('other', '您尚未勾選"本人已詳細閱讀隱私權政策"')
      else {
        if (this.$route.query.from === 'paymentRecord') this.$router.push({name: 'PayPlan', query: this.$route.query})
        else this.$router.push('/register')
      }
    },
  },
}
</script>